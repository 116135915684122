/* You can add global styles to this file, and also import other style files */
@import "theme/bootstrap_vars";
@import "~bootstrap/scss/bootstrap";

// not overly happy with calling the file directly but keeps the "brand styles" in the ui-kit
@import "../../../libs/ui-kit/src/lib/theme/styles";
@import "theme/material_inputs";
@import "theme/tables";
@import "theme/tabs";
@import "theme/inputs";
@import "theme/badge";

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.col-split-left {
  border-left: 3px solid $gap-select-light-gray;
}
.col-split-right {
  border-right: 3px solid $gap-select-light-gray;
}

// Custom dropdowns for global use
// use `.btn-invisible-dropdown` to remove button styles for toggle
[ngbDropdown] > button.btn-invisible-dropdown {
  width: 100%;
  height: 100%;
  background: none;
  text-align: left;
  background-color: $gap-select-light-gray;

  &::after {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

[ngbDropdown] > .dropdown-menu {
  background-color: $gap-blue;
  min-width: 5rem;
  width: 100%;
  border-radius: 0;
  border: 0;

  .dropdown-item {
    color: white;
    padding-left: 15px;
    transition: all 0.2s ease-in-out;
    white-space: unset;

    &:hover,
    &:focus {
      background-color: $gap-super-light-blue;
      border: 0;
      border-radius: 0;
      padding-left: 20px;
    }
  }
}

.mat-form-field,
input.mat-input-element,
.mat-form-field-label {
  font-family: "Open Sans", sans-serif !important;
}

input.mat-input-element,
.mat-form-field-label {
  font-size: 1rem !important;
}

.mat-form-field-label-wrapper {
  top: -0.5em !important;
}

.minvh100 {
  min-height: 100vh;
}

.top-logo {
  max-width: 125px;
}

.gap-login-section {
  max-width: 380px;
  margin: 0 auto;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  color: #757575;

  a {
    &:hover {
      color: $gap-blue;
      text-decoration: none;
    }
  }
}

.spinner-border {
  color: $gap-blue;
}

.gap-login-helper {
  font-size: 1.6rem;
  font-weight: 100;
}

.carousel-caption {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  right: 0;
  bottom: 0;
  left: 0;

  p {
    max-width: 380px;
    margin: 15px auto 10px;
    font-weight: 300;
    color: #fff;
  }
}

ol.carousel-indicators {

  li {
    width: 10px;
    height: 10px;
    border-radius: 90%;
  }
}

.gap-depot-search {
  position: absolute;
  bottom: 3rem;
  text-align: center;
  width: 100%;
  color: white;
}

.mat-form-field-subscript-wrapper {
  font-size: 60% !important;
}

*:focus {
  outline: none;
}

h1 {
  color: #18214d;
  font-weight: 600;
}

.main-menu{

  ul{
    li{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  a, a:hover {
    color:#fff;
    text-decoration: none;
  }
}

.main-body h2{
    color: $gap-blue;
    font-weight:600;
    font-size: 1.7rem;
    margin-bottom: 0;
  }

.main-body p {
  color: $gap-gray;
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.main-body h4 {
  color: $gap-blue;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.red-anchors {
  color: $gap-red;

  a {
    color: $gap-red;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }
}

.login-container-col.fs-party {
  background-image: url(/assets/img/conffetti.png);
  background-repeat: no-repeat;
  background-position-x: center center;
  background-size: cover;

  .gap-login-section {
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    background-color: white;
  }
}

.red-buttons-base {
  border: 0;
  color: #fff;
  padding: 12px 75px;
  transition: all 0.2s ease-in-out;

  font-weight: bold;

  border-radius: 3.84px;
  background-color: #e32526;
  box-shadow: 0 7px 12px 4px rgba(0, 0, 0, 0.17);
  margin-top: 1rem;

  &:hover {
    transform: scale(1.025);
    cursor: pointer;
  }
}

.close-menu:hover {
  cursor: pointer;
}

.prettydropdown {
  display: block;
  width: 100% !important;

  ul {
    width: 100% !important;
    border: 0;
    background: transparent;
    font: inherit;

    li {
      width: 100% !important;
      background: $gap-blue;
      color: #fff;
      border-bottom: 1px solid white;

      &:nth-child(2) {
        margin-top: 15px;
      }

      &:first-child {
        background: $gap-select-light-gray;
        color: $gap-gray;
      }

      &.selected::before {
        right: 16px !important;
      }

      &.selected::after {
        border-top-color: transparent !important;
      }

      span.checked {
        display: none !important;
      }
    }
  }
}

/*
Reports
 */
.full-width {
  margin-left: -3rem;
  margin-right: -3rem;
  width: auto;
}

.report {
  .dataTables_wrapper {
    width: 100%;
  }

  ngb-pagination {
    .page-item {
      .page-link {
        background: $gap-gray;
        border-color: $gap-gray;
        color: white;
        margin: 1px;
        border-radius: 2px;
        &:hover {
          background: $gap-light-blue;
          color: white;
        }
      }

      &.active .page-link,
      .page-link:hover {
        background: $gap-light-gray;
        border-color: $gap-light-gray;
      }
    }
  }
}

ngb-datepicker-navigation{
  position:relative;

  &::before{
    content: '-';
    color: white;
    text-align: center;
    position: absolute;
    width: calc(100% - 5px);
    z-index: 1;
    pointer-events: none;
  }
}

.ngb-dp-navigation-select{
  flex:unset !important;
  position:relative;

  select{
    border: 0;
    color: white;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 2px;
    flex:unset !important;
    background: $gap-blue;
    padding:0 .75rem !important;
    border: 1px solid #f6f6f6;

    &:first-child{
      margin-right:0.75rem;
    }

    &:last-child{
      margin-left:0.75rem;
    }

    &:focus{
      box-shadow: none;
      border: 1px solid #f6f6f6;
    }

    &:hover {
      color: $gap-blue;
      background: rgba(225, 225, 225, 0.85)
    }
  }

  &::after{
    left:auto;
    right: -5px;
  }
}

.breadcrumbs{
  font-weight:600;
  color:#212C65;

  a{
    text-decoration:none;
  }

  .material-icons{
    vertical-align: bottom;
    color: darkgrey;
    font-size:22px;
    font-weight: 600;
  }
}

.no-a-link{
  &:hover{
    cursor:pointer;
  }
}

.reset-password .modal-dialog{
  max-width:80%;

  .main-body{
    background-color: #fff;
  }
}

.break-all{
  word-break: break-all;
}

.font-w-100{
  font-weight:100;
}

.auth-alerts{
  div{
    border:0;
    border-radius:0;
    margin-bottom:0;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:9999;
  }
}

@media screen and (max-width:575px){

  .reset-password .modal-dialog{
    max-width:100%;
  }

}

@media screen and (max-width: 767px) {
  .main-body {
    &.p-5 {
      padding: 1rem !important;
    }
  }

  .mt-mob-3 {
    margin-top: 3rem;
  }

  .red-buttons-base {
    width: 100%;
    margin-top: 0.5rem !important;
  }

  .gap-depot-search {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    color: white;
    background: $gap-blue;
    padding: 1rem;
  }

  .carousel {
    display: none;
  }

  .minvh100 {
    min-height: auto;
  }

  .gap-login-section {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .login-container-col {
    padding: 3rem 1.5rem 1.5rem !important;
    text-align: center;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 16px;
    }

    .login {
      margin-top: 0 !important;
    }
  }
}

.material-icons.disabled {
  color: #bfc1c3;
}
.the-box{
	position: relative;
	box-shadow: 0px 0px 15px 1px lightgrey;
	border-radius: 5px;
	height: 100%;
	background-color: white;

  h4{
    color: $gap-blue;
    font-weight: 600;
    font-size: 1.7rem;
    margin-bottom: 0;
    margin-top:0;
  }

  .the-desc{
    font-size: .9rem;
    font-weight: 400;
  }

  &.pinned-insight{
    h4{
      font-size: 1.2rem;
      margin-bottom: 40px;
    }

    .the-desc{
      position: absolute;
      bottom: 15px;
      left: 25px;
      right: 25px;
      color: grey;
      font-size: .8rem;
      font-weight: 300;
      }
  }

  &.big-border-right{
    border-left: 5px solid $gap-blue;
  }
}
.seg-1{
	padding:25px !important;
}

.seg-2{
	padding:15px !important;
  box-shadow: -2px 0px 0px 0px lightgrey;

  img{
    max-height:60px;
  }
}

::-ms-clear {
  display: none;
}
// carousel hacks
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.carousel-item.active {
  animation: fadein 2.1s;
}
