.nav-tabs .nav-link.disabled {
  color: $gap-blue;
}

.nav-tabs .nav-link.active {
  background: transparent;
  border: none;
  border-bottom: 3px solid $gap-blue;
  font-weight:600;
  color: $gap-blue;
}

.nav{
  &.nav-tabs{
    box-shadow: inset 0px -2px 0px $gap-select-light-gray;
    margin-left: -3rem;
    margin-right: -3rem;
    align-items:flex-end;
  }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
}

@media screen and (max-width: 991px){
  .nav{
    &.nav-tabs{
      margin-left:-30px;
      margin-right:-30px;
    }
  }
}
