// overrides for bootstrap buttons
$box-shadow: 0 7px 12px 0px rgba(0, 0, 0, 0.17);

button{
  &:focus{
    outline:0;
  }
}

//[class*="btn-"],
.btn-primary,
.btn-secondary,
.btn:not(:disabled):not(.disabled):active{
  &:focus{
    box-shadow: $box-shadow;
  }
}

.btn {
  border: 0;
  padding: 12px 75px;
  transition: all .2s ease-in-out;
  box-shadow: $box-shadow;
  font-weight: 700;
  border-radius: 3.84px;

  &.btn-primary{
    color: #fff;
    background-color: #E32526;
  }

  &.w-100{
    padding:12px 5px;
  }

  &:hover{
    transform: scale(1.025);
    cursor: pointer;
  }
}

.btn-sm {
  padding: 10px 25px;
}

.btn-ghost {
  border-color: none;
  background-color: transparent;
}

// will fail if bootstrap scss is not imported first
@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    color: $value;
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;

    &:hover, &:focus {
      box-shadow: none;
      transform:none;
      color: $value;
    }

    &.btn:not(:disabled):not(.disabled):active{
      &:focus{
        box-shadow: none;
      }
    }
  }
}
