.table-controls {
  background: $gap-dark-gray;
  width: 100%;
  height: 50px;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5px;
  color: white;
  line-height: 50px;
  display: flex;

  > .btn-group {
    margin-right: 40px;
  }

  .btn.btn-outline-dark {
    color: white;
    background-color: $gap-light-gray;
    border-color: $gap-gray;
  }

  .page-size-selection {
    display: inline-flex;
  }

  ngb-pagination {
    display: inline-flex;
  }

  .page-item {
    .page-link {
      background: $gap-gray;
      border-color: $gap-gray;
      color: white;
      margin: 1px;
      border-radius: 2px;
    }

    &.active .page-link, .page-link:hover {
      background: $gap-light-gray;
      border-color: $gap-light-gray;
    }
  }
}

.table-count {
  padding-left: 3rem;
  color: $gap-gray
}
