$gap-blue: #212C65;
$gap-light-blue: #303C76;
$gap-super-light-blue: #404A7F;
$gap-very-light-gray: #F6F6F6;
$gap-light-gray: #9C9C9C;
$gap-gray: #525252;
$gap-dark-gray: #383838;
$gap-red: #E32526;
$gap-dark-blue: #18214D;
$gap-select-light-gray: #e9e9e9;
