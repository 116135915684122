// Brand variables used previously in components
@import "_vars";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
// For source of all variables check ~/node_modules/bootstrap/scss/_variables.scss

$gray-100: $gap-very-light-gray;
$gray-200: $gap-select-light-gray;
$gray-500: $gap-light-gray;
$gray-700: $gap-gray;
$gray-900: $gap-dark-gray;

$blue:    $gap-blue;
$red:     $gap-red;
$colors: (
  "gray":       $gray-700,
  "gray-dark":  $gray-900
);
$secondary:     $red;

$font-family-sans-serif:      'Open Sans', sans-serif;

$input-bg: $gray-200;

$grid-gutter-width: 60px;
