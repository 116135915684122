.form-control{

  height:auto;

  &.dropdown{
    border:0;

    .dropdown-toggle{
      width: 100%;
      height: 3rem;
      background: $gap-select-light-gray;
      padding-left: 10px;
      color:$gap-blue;
      border: 0;
      border-radius: 0;
      -webkit-appearance: none;
      font-weight: 600;
      font-size: .9rem;

      &::after{
        display:none;
      }
    }

    .account-dropdown{
      height:100%;
    }
  }
}

label{
  margin-bottom: 0;
    color: $gap-blue;
    font-weight: 600;
}

.account-dropdown{
  position: absolute;
  right: 10px;
  bottom: 0;
  height: calc(100% - 15px);
  color: $gap-blue;
  pointer-events: none;
  line-height: 3rem;
}

.search-icon{
  position: absolute;
  z-index: 4;
  left: 40px;
  bottom: 0;
  height: 100%;
  color: $gap-blue;
  pointer-events: none;
  line-height: 3rem;
}

select,textarea,input[type='text'],input[type='password'],input[type='email'],input[type='number']{
  width: 100%;
  height: 3rem;
  background: $gap-select-light-gray;
  padding-left: 10px;
  color:$gap-blue;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  margin-top: 15px;
  font-weight: 600;
  font-size: .9rem;

  &:focus{
    outline:0;
    box-shadow: none;

  }
}

input{
  &.placeholder-with-icon{
    padding-left:40px;
  }
}

textarea{
  height: 9rem;
  padding: 10px;
  display:block;
}

select{
  &:hover{
    cursor:pointer;
  }
}

.form-check-label{
  font-weight: 400;
  font-size: .9rem;
}

.form-check-input{
  border: 2px solid $gap-blue;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    font-size: .75rem;
    line-height: .75rem;
    text-align: center;

    &:after{
      content:'\2713';
      color:#fff;
    }

    &:checked{
      background-color:$gap-blue;
    }
}

.invalid-feedback{
  margin-top:0;
  color:$gap-red;
  border-top:2px solid $gap-red;
}

input.dropdown-toggle{
  background:transparent;
  &:focus{
    background:transparent;
  }
}

.dropdown{
  background-color:$gap-select-light-gray;
  &.show{
    background-color:$gap-super-light-blue;
    input,input::placeholder{
      color:#fff;
    }
  }
}

.dropdown-menu{
  overflow-y: scroll;
  max-height: 50vh;
  margin-top:0;
}

.input-group-append i.material-icons,
.input-group-prepend i.material-icons{
  border: 0;
  border-radius:0;
  color: $gap-blue;
  font-weight: 600;
  font-size: 1.25rem;
}

// Datepicker styles

ngb-datepicker.dropdown-menu.show {
  border: 0;
  border-radius:0;
  background: $gap-blue;
  padding: 1rem 2rem 2rem !important;
  //width: calc(100% - 3.75rem);
  width:100%;
  max-height: unset;

  .ngb-dp-header {
    background-color: transparent !important;
  }

  ngb-datepicker-navigation{

    .btn:hover {
      transform:none;
    }

    .ngb-dp-arrow-btn{

      box-shadow: none;

      &:focus{
        outline:none;
      }
    }

    .ngb-dp-arrow{
      justify-content:right;
      display:block;
      text-align:right;

      &.right{
        justify-content:normal;
        text-align:left;
      }
    }

    .ngb-dp-navigation-chevron{
      color: white;
      font-size: .75rem;
    }

    .ngb-dp-month-name{
      font-size: 1rem;
      color: white;
      font-weight: 600;
    }
  }

  .bg-light{
    background-color: transparent !important;
  }

  .ngb-dp-month{
    width:100%;
  }

  .ngb-dp-weekday,.ngb-dp-day{
    width:14.28%;
    color: white;
    font-style: unset;
  }


  .ngb-dp-week{
    border-radius: 0;
    padding: 0;

    &.ngb-dp-weekdays{
      background-color: transparent !important;
      border-top:0;
      border-left:0;
      border-bottom:0;
    }

    &:last-child{
      padding-bottom:0;
    }
  }

  .ngb-dp-day{

    height:auto;

    .btn-light {
      width:100%;
      border-radius:0;
      text-align: center;
      height: 2rem;
      line-height: 2rem;
      background: 0 0;
      color:white;
    }

    &:not(.disabled) {
      .btn-light {
        border-left: 1px solid $gap-super-light-blue;
        border-top: 1px solid $gap-super-light-blue;
        box-shadow: 1px 1px 0px $gap-super-light-blue;
        &.bg-primary,&:hover,&.range{
          background-color:$gap-very-light-gray;
          color:$gap-blue;
        }
        &.faded {
          opacity: 0.6;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      .btn-light {
        opacity: 0.65;
      }
    }
  }
}

// end datepicker

.gap-multi-select{
  .multiselect-dropdown{
    .dropdown-btn{
      border: 0 !important;
      padding: 14px 10px !important;
      font-weight: 600 !important;
      line-height: 1rem !important;
      border-radius: 0 !important;
      background: #e9e9e9;
      color: $gap-blue;
      font-size: .9rem;

      .dropdown-up,
      .dropdown-down{
        display:none !important;
      }

      .selected-item{
        border: 0 !important;
        margin-right: 4px !important;
        background: transparent !important;
        padding: 0 5px !important;
        border-radius: 0 !important;
        color: $gap-blue !important;
        font-weight:400;

        a{
          text-decoration: none;
          vertical-align: bottom;
          background: $gap-blue;
          padding: 0px 5px 1px !important;
          display: inline-block;
          border-radius: 3px;
          font-size: .75rem;
          margin-left: 5px;
        }

        &:hover{
          box-shadow: none !important;
        }
      }
    }

    .dropdown-list{
      padding-top: 8px;
      padding-bottom: 8px;
      border: 0;
      border-radius: 0;
      background: $gap-blue;
      box-shadow: none;
      margin-top: 8px;
      max-height: 15rem;
      overflow-y: scroll;

      li{
        padding: 6px 15px;

        &.multiselect-item-checkbox input[type=checkbox]{

          + div {
            color: #fff;
            font-size:0.9rem;

            &:after {
              top: 50%;
              left: 3px;
              width: 7px;
              height: 3px;
              margin-top: -3px;
              border-color: $gap-blue;
              border-width: 0 0 1px 1px;
            }

            &:before {
              border: 1px solid #fff;
              border-radius: 3px;
              width: 12px;
              height: 12px;
              margin-top: -7px;
            }
          }

          &:checked + div:before {
            background: white;
          }
        }

        &.no-data h5 {
          color: white;
        }
      }
    }
  }
}

.alternate-form-styling{

  label{
    font-size: .9rem;
    color: grey;
    font-weight: 400;
  }

  input{
    background-color:transparent;
    border-bottom:1px solid grey;
    margin-top:0;
    font-weight:100;
  }

  .material-icons{
    left: 0;
    top: 0;
    line-height: 3rem;
    color: $gap-blue;
    font-size:1.5rem;
  }
}
